.App {
  text-align: center;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.page-container {
  padding: 1rem;
}

.primary-button {
  border-radius: 16px;
  border: none;
  background-color: #00b33c;
  color: white;
  padding: 1rem;
}

.primary-button a {
  color: white;
}

.display-card-page-button {
  width: 100%;
}
