.card_editor_main_container {
  padding: 1rem;
}

.card_editor_container {
  display: flex;
  flex-direction: column;
  height: 80%;
}

.card_editor_main {
  display: flex;
  flex-direction: row;
}

.card_editor_main .card_editor_child {
  flex: 1;
}

.card_editor_sidebar {
  max-width: 15%;
}

.card_editor_editor {
  max-width: 30%;
}

.card_editor_inline_group {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.5rem !important;
}

.card_editor_value {
  min-width: 60%;
}
