.card_editor_summary_container {
  display: flex;
  flex-direction: row;
  padding: 0 1rem;
  justify-content: space-between;
}

.card_editor_summary_info {
  flex: 1;
  max-width: 40rem;
}

.card_editor_summary_container > .btn-toolbar {
  display: flex;
  align-items: center;
}

.card_editor_summary_container > .btn-toolbar button {
  flex: 1;
  min-width: 5rem;
}
