.wire_frame_component_selected {
  border: 1px red solid !important;
}

.wire_frame_component_highlighted {
  border: 1px red dashed !important;
}

.wire_frame_component_dropping {
  background-color: orange !important;
}

.wire_frame_component_container {
  cursor: pointer;
}

.wire_frame_component_p {
  padding: 0 1rem;
  margin: 0;
  min-height: 1rem;
}

.wire_frame_component_text {
  border: none;
  padding: 0;
  margin: 0;
  background: inherit;
  font: inherit;
}

