form {
  padding: 1rem;
}

.name_fields {
  display: flex;
  align-items: center;
}

.name_fields .control-label {
  margin-right: 1rem;
  min-width: 10rem;
  max-width: 25%;
}

.name_fields input:not(:last-child) {
  margin-right: 1rem;
}

.people_editor_inline_group {
  display: flex;
  align-items: center;
}

.people_editor_inline_group .control-label {
  margin-right: 1rem;
  min-width: 10rem;
  max-width: 25%;
}
