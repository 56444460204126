.editable_field_container {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editable_field_label {
  flex: 1;
}

.editable_field_editor {
  flex: 2;
  display: flex;
  align-items: center;
}

.editable_field_value {
  text-align: right;
  flex: 2;
}

.editable_field_button {
  margin-left: 1rem;
}
