.cb_header_container {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cb_header_container button {
  margin-right: 2rem;
}

.cb_header_menu {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 1rem;
  margin: 0;
}

.cb_header_menu li {
  padding-left: 1rem;
}

.cb_header_selected {
  font-weight: bold;
}
