.component_tree_header_container {
  display: inline-block;
  vertical-align: top;
  color: black;
}

.component_tree_header_name {
  vertical-align: middle;
}

.component_tree_selected {
  font-weight: bold;
}
