.display_card_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
}

.display_card_card_holder {
  border: 1px solid grey;
  width: 300px;
  height: 200px;
}

.display_card_userinfo {
  max-width: 90%;
}

/* Regular size phone */
@media (min-width: 325px) {
  .display_card_card_holder {
    width: 350px;
    height: 233px;
  }
}
