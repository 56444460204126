.pallette_container {
  list-style: none;
  padding: 1rem;
  margin: 0;
}

.pallette_container li {
  margin-bottom: 1rem;
}

.pallette_item {
  border: 1px solid black;
  cursor: move;
}

.pallette_item_dragging {
  opacity: 0.25;
}
